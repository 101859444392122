import { breadcrumbsClasses } from '@mui/material';
import { PRIORIDADES_TICKETS, TIPOS_TICKETS, ESTADOS_TICKETS } from '../constants';

// estados disponibles para transicionar
export const getEstadosDisponibles = (estadoActual) => {
    switch(estadoActual) {
        case ESTADOS_TICKETS.abierto:
          return [ESTADOS_TICKETS.abierto, ESTADOS_TICKETS.enProgreso, ESTADOS_TICKETS.bloqueado, ESTADOS_TICKETS.pendiente]
        case ESTADOS_TICKETS.enProgreso:
          return [ESTADOS_TICKETS.enProgreso, ESTADOS_TICKETS.abierto, ESTADOS_TICKETS.bloqueado, ESTADOS_TICKETS.pendiente, ESTADOS_TICKETS.solucionado]
        case ESTADOS_TICKETS.bloqueado:
            return [ESTADOS_TICKETS.bloqueado, ESTADOS_TICKETS.abierto]
        case ESTADOS_TICKETS.pendiente:
            return [ESTADOS_TICKETS.pendiente, ESTADOS_TICKETS.abierto, ESTADOS_TICKETS.finalizado]
        case ESTADOS_TICKETS.solucionado:
            return [ESTADOS_TICKETS.solucionado, ESTADOS_TICKETS.enProgreso, ESTADOS_TICKETS.finalizado]
        default:
            return [ESTADOS_TICKETS.finalizado]
      }
}

//TODO exigir campo solucion y actualizar alguna fecha si corresponde
export const pasoASolucionado = () => {

}

//TODO exigir campo razon y actualizar fecha 
//TODO tener un select con "razones"
export const pasoACerrado = () => {

}

