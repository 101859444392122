import React, { useState, useEffect } from "react";
import { CommentSection } from "react-comments-section";
import "react-comments-section/dist/index.css";
import { BACKEND_URL, ADD_COMMENTS_PATH } from '../../../constants';

const CommentsComponent = ({ticket_id, comentarios = [], getLogginInfo, execute}) => {

  /*
  const data = comments? comments : [
    {
      userId: "02b",
      comId: "017",
      fullName: "Lily",
      userProfile: "https://ui-avatars.com/api/?name=John+Doe",
      text: "I think you have a point🤔",
      avatarUrl: "https://ui-avatars.com/api/?name=John+Doe&background=063970&color=fff",
      replies: []
    }
  ];

  */
 
  const [currentName, setCurrentName] = useState('Usuario');
  const [currentId, setCurrentId] = useState(null);
  const [nombre, setNombre] = useState(null);

  const data_ = [
    {
      userId: "02b",
      comId: "017",
      fullName: "Lily",
      userProfile: "https://ui-avatars.com/api/?name=John+Doe",
      text: "I think you have a point🤔",
      avatarUrl: "https://ui-avatars.com/api/?name=John+Doe&background=063970&color=fff",
      replies: []
    }
  ];

  const onSubmitAction = (data) => {
    data['ticket_id'] = ticket_id;
    let userId = data['userId']['currentId'];

    data['userId'] = userId;
    data['fullName'] = currentName;
    data = {...data, ['replies']: []};

    if (execute)
      execute("POST", `${BACKEND_URL}${ADD_COMMENTS_PATH}`, data).then((r) => {
        if (r) {
          console.log("Comentario creado correctamente: " + JSON.stringify(r));
        }
      })
  }

  let customNoComment = () => <div className='no-com'>No comments wohoooo!</div>
  
  useEffect(() => {
    let { currentEmail, currentName, currentId } = getLogginInfo();
    setCurrentName(currentName);
    setCurrentId(currentId);
    setNombre(currentName.split(" "));
  }, []);

  return (
    <>{comentarios && currentId && nombre && currentName && comentarios != [] && 
      <CommentSection
        currentUser={{
          currentUserId: {currentId},
          currentUserImg:
            `https://ui-avatars.com/api/?name=${nombre[0]}+${nombre[1]}&background=063970&color=fff`,
          currentUserProfile: `https://ui-avatars.com/api/?name=${nombre[0]}+${nombre[1]}`,
          currentUserFullName: '',
        }}
        commentData={comentarios}
        onSubmitAction={(data) => onSubmitAction(data)}
        onReplyAction={(data) => onSubmitAction(data)}
        titleStyle={{ fontSize: '1.2rem' }}
        formStyle={{ backgroundColor: 'white' }}
        advancedInput={true}
        submitBtnStyle={{
            border: '1px solid black',
            backgroundColor: '#abaca4',//el rojo de rng
            padding: '7px 15px'
          }}
        customNoComment={() => customNoComment()}
      />
    }</>
  );
};

export default CommentsComponent;