import { useMemo } from "react";
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';

import {
  MaterialReactTable,
  useMaterialReactTable
} from 'material-react-table';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

function UsersTable({ data, handleRefresh }) {
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  /* "businessPhones": [],
      "displayName": "Abel Shannon",
      "givenName": "Abel",
      "jobTitle": "Analista de Ingeniería",
      "mail": "abel.shannon@nrgproppants.com",
      "mobilePhone": null,
      "officeLocation": "Buenos Aires ",
      "preferredLanguage": null,
      "surname": "Shannon",
      "userPrincipalName": "abel.shannon@nrgproppants.com",
      "id": "9018450b-9d1e-43ba-84b3-bd4d6f8524d4"
       */
  const columns = useMemo(
    () => [
      {
        accessorKey: 'displayName',
        header: 'Nombre',
        size: 220,
      },
      {
        accessorKey: 'jobTitle',
        header: 'Puesto',
        size: 200,
      },
      {
        accessorKey: 'mail',
        header: 'Email',
        size: 300,
      },
      {
        accessorKey: 'officeLocation',
        header: 'Ubicacion',
        size: 250,
      }
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)

    defaultDisplayColumn: {
      enableResizing: true, //turn on some features that are usually off for all display columns
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 150, //set custom width
        muiTableHeadCellProps: {
          align: 'center', //change head cell props
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      'mrt-row-numbers': {
        enableColumnDragging: true,
        enableColumnOrdering: true, //turn on some features that are usually off
        enableResizing: true,
        muiTableHeadCellProps: {
          sx: {
            fontSize: '1.2rem',
          },
        },
      },
      'mrt-row-select': {
        enableColumnActions: true,
        enableHiding: false,
        size: 40,
      },
    },
    enableColumnResizing: true,
    enableColumnOrdering: true,
    enableRowNumbers: true,
    enableRowSelection: true,
    enableRowActions: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Exportar todo
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Exportar pagina
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Exportar filas Seleccionadas
        </Button>
      </Box>
    ),
    getRowId: (originalRow) => originalRow._id,
    initialState: { columnVisibility: { _id: false, nombre: false, ubicacion: false, fechaCompra: false, proveedor: false, imei: false, numeroAsignado: false, compania: false, ram: false, disco: false, so: false, remito: false, tipo: false } }, //hide firstName column by default
  });

  return <>
    <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
      <div className="flex justify-between pt-2 pb-3 px-3">
        <div className="flex gap-4 justify-center items-center ">
          
        </div>
        <div className="flex gap-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
            onClick={handleRefresh}
          >
            Actualizar Usuarios
          </button>
        </div>
      </div>
      <MaterialReactTable table={table} />
    </div>
  </>;
}

export default UsersTable;
