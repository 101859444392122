export const POSTURL = "http://localhost:4000/api/v1/patterns"
export const DELETEURL = "http://localhost:4000/api/v1/patterns/"

/* 
export const DeleteButton = require('../images/delete-icon.png')
export const LoadingWheel = require('../images/loading-wheel.gif')
 */

/* TICKETS */
export const ESTADOS_TICKETS = {abierto: 'Abierto', enProgreso: 'En Progreso', pendiente: 'Pendiente', bloqueado: 'Bloqueado', solucionado: 'Solucionado', finalizado: 'Cerrado'}
export const COLORES_ESTADOS_TICKETS = {'Abierto': "#17469e", 'En Progreso': "#17469e", 'Pendiente': "#b72842", 'Bloqueado': "#b72842", 'Solucionado': "#b72842", 'Cerrado': "#b72842"}
export const TIPOS_TICKETS = {issue: 'Issue', tarea: 'Tarea', bug: 'Bug'}
export const PRIORIDADES_TICKETS = {baja: 'Baja', media: 'Media', alta: 'Alta'}
export const DEFAULT_TICKET = {
    estado: ESTADOS_TICKETS.abierto ,
    tipo: TIPOS_TICKETS.issue,
    id_generado: "",
    prioridad: PRIORIDADES_TICKETS.baja,
    title: "",
    description: "",
    adjuntos: "",
    asignadoA: "",
    idAsignadoA: "",
    bloqueadoPor: [],
    observadores: [],
    fechaCreacion: "",
    creadoPor: "",
    idCreadoPor: "",
    fechaCreacion: "",
    fechaActualizacion: "",
    fechaResolucion: "",
    solucion: "",
    fechaEsperada: "",
    tiempoTrabajado: 0,
    tiempoEstimado: ""
  }


/* ASSETS */
export const ESTADOS_ASSETS = {asignado: 'Asignado', enReparacion: 'En Reparacion', sinAsignar: 'Sin Asignar', obsoleto: 'Obsoleto', extraviado: 'Extraviado', robado: 'Robado'}
export const TIPOS_ASSETS = {fisico: 'Fisico', digital: 'Digital'}

export const BACKEND_URL = "https://nrg-servicios.eastus.cloudapp.azure.com:8443/"
export const GET_ASSETS_PATH = "api/asset/get"
export const GET_TICKETS_PATH = "api/tickets/getAll"
export const GET_OPCIONES_BLOQUEADO_POR = "api/tickets/getOpcionesBloqueadoPor"
export const UPDATE_TICKETS_PATH = "api/tickets/update"
export const GET_ADJUNTOS = "api/tickets/getAdjuntos/"
export const POST_ADJUNTOS_PATH = "api/tickets/insertAdjuntos"
export const POST_TICKET_PATH = "api/tickets/add"
export const GET_COMENTARIOS = "api/tickets/getComments/"
export const ADD_COMMENTS_PATH = "api/tickets/addComment"

export const GET_ASSET_BY_CAT_PATH = "api/asset/getByCategory"
