import { Routes, Route  } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

import "./index.css";
import { RouteGuard } from './components/RouteGuard';
import Layout from "./components/Layout";

import Inventory from "./pages/Inventory";
import Tickets from "./pages/Board";
import MainSettings from "./pages/MainSettings";
import Dashboard from "./pages/Dashboard";
import { Overage } from './pages/Overage';

import { groups } from './authConfig';

const Pages = () => {
    return (
        <Routes>
            <Route path="/overage" element={<Overage />} />

            <Route
                path="/"
                element={ <Layout /> } >
                <Route index element={<RouteGuard requiredGroups={[groups.groupMember, groups.groupAdmin]}>
                <Dashboard />
                    </RouteGuard>} />
                <Route path="/inventory" element={<RouteGuard requiredGroups={[groups.groupMember, groups.groupAdmin]}>
                <Inventory />
                    </RouteGuard>} />
                <Route path="/tickets" element={<RouteGuard requiredGroups={[groups.groupMember, groups.groupAdmin]}>
                <Tickets />
                    </RouteGuard>} />
                <Route path="/ticket/:id" element={<RouteGuard requiredGroups={[groups.groupMember, groups.groupAdmin]}>
                <Tickets />
                    </RouteGuard>} />
                <Route path="/settings" element={<RouteGuard requiredGroups={[groups.groupAdmin]}>
                <MainSettings />
                    </RouteGuard>} />
          </Route>

        </Routes>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <Pages />
        </MsalProvider>
    );
};

export default App;
