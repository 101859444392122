import React, { useEffect, useState } from "react";
import { protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { BACKEND_URL, ESTADOS_ASSETS,GET_ASSETS_PATH, GET_ASSET_BY_CAT_PATH } from '../constants';
import Button from 'react-bootstrap/Button';

import Chart from 'chart.js/auto';

// TODO tengo el backup hecho, tendria que probar a hacerlo a como hice el pie
ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard() {
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const etiquetas = [ESTADOS_ASSETS.asignado, ESTADOS_ASSETS.sinAsignar, ESTADOS_ASSETS.enReparacion, ESTADOS_ASSETS.obsoleto, ESTADOS_ASSETS.extraviado];
  const [chart, setChart] = useState(null);
  const [chartAreas, setChartAreas] = useState(null);
  const [byAreas, setByAreas] = useState(null);
  const [filtroPorCategoria, setFiltroPorCategoria] = useState(null);
  const [pieOptions, setPieOptions] = useState(null);
  const [data, setData] = useState(null);
  const [printChart, setPrintChart] = useState(false);
  const [byCategories, setByCategories] = useState(null);
  const [assets, setAssets] = useState(null);

  useEffect(() => {
    fetchAssetByCategory();
    fetchAssetByArea();
    fetchAssetsData();
  }, [execute]);


  const settingChartArea = (labels_, data_) => {
    const config = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (data) {
              return "Total: " + data.formattedValue;
            },
          },
        },
        datalabels: {
          color: "white"
        },
      },
    };

    const data = {
      labels: labels_,
      datasets: [
        {
          label: labels_,
          data: data_,
          borderWidth: 1,
        },
      ],
    };

    setChartAreas({ data: data, config: config });
  }

  const settingChart = (labels_, data_) => {
    const config = {
      onClick: function (evt, element) {
        const clickedElement = element[0];
        const datasetIndex = clickedElement.index;
        const label = data_[datasetIndex];
        const labelValue = labels_[datasetIndex];

        setFiltroPorCategoria(labelValue);
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (data) {
              return "Total: " + data.formattedValue;
            },
          },
        },
        datalabels: {
          color: "white"
        },
      },
    };

    const data = {
      labels: labels_,
      datasets: [
        {
          label: labels_,
          data: data_,
          borderWidth: 1,
        },
      ],
    };

    setChart({ data: data, config: config });
    setPrintChart(true);
  }

  const settingPieData = (values) => {
    setData({
      labels: etiquetas,
      datasets: [
        {
          label: "Cantidad",
          data: values,
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    });
  }

  const [totales, setTotales] = useState(0);
  const [enReparacion, setEnReparacion] = useState(0);
  const [asignados, setAsignados] = useState(0);
  const [obsoletos, setObsoletos] = useState(0);
  const [extraviados, setExtraviados] = useState(0);
  const [sinAsignar, setSinAsignar] = useState(0);

  useEffect(() => {
    if (assets) {
      let assets_;
      if (filtroPorCategoria)
        assets_ = assets.filter(a => a.categoria == filtroPorCategoria.toLowerCase()) || null;
      else
        assets_ = assets;

      let asignados = assets_.filter(a => a.estado == ESTADOS_ASSETS.asignado)?.length || 0;
      let enReparacion = assets_.filter(a => a.estado == ESTADOS_ASSETS.enReparacion)?.length || 0;
      let obsoletos = assets_.filter(a => a.estado == ESTADOS_ASSETS.obsoleto)?.length || 0;
      let extraviados = assets_.filter(a => a.estado == ESTADOS_ASSETS.extraviado)?.length || 0;
      let sinAsignar = assets_.filter(a => a.estado == ESTADOS_ASSETS.sinAsignar)?.length || 0;
      let totales = assets_.length;

      setTotales(totales);
      setEnReparacion(enReparacion);
      setAsignados(asignados);
      setObsoletos(obsoletos);
      setExtraviados(extraviados);
      setSinAsignar(sinAsignar);

      let data_ = [asignados, sinAsignar, enReparacion, obsoletos, extraviados];
      settingPieData(data_);

      setPieOptions({
        plugins: {
          legend: {
            display: true,
            labels: { font: { size: 14 } }
          },
          datalabels: {
            display: true,

            formatter: (value, ctx) => {
              if (value > 0) {
                const total = ctx.chart.getDatasetMeta(0).total;
                let percentage = (value * 100 / total).toFixed(2) + "%";
                return percentage + '(' + value + ')';
              } else {
                value = "";
                return value;
              }
            }
          }
        }
      });

    }
  }, [filtroPorCategoria]);

  useEffect(() => {

    if (assets){
    let asignados = assets.filter(a => a.estado == ESTADOS_ASSETS.asignado)?.length || 0;
    let enReparacion = assets.filter(a => a.estado == ESTADOS_ASSETS.enReparacion)?.length || 0;
    let obsoletos = assets.filter(a => a.estado == ESTADOS_ASSETS.obsoleto)?.length || 0;
    let extraviados = assets.filter(a => a.estado == ESTADOS_ASSETS.extraviado)?.length || 0;
    let sinAsignar = assets.filter(a => a.estado == ESTADOS_ASSETS.sinAsignar)?.length || 0;
    let totales = assets.length;

    setTotales(totales);
    setEnReparacion(enReparacion);
    setAsignados(asignados);
    setObsoletos(obsoletos);
    setExtraviados(extraviados);
    setSinAsignar(sinAsignar);

    settingPieData([asignados, sinAsignar, enReparacion, obsoletos, extraviados]);
  }
  
  setPieOptions({
    plugins: {
      legend: {
        display: true,
        labels: { font: { size: 14 } }
      },
      datalabels: {
        display: true,

        formatter: (value, ctx) => {
          if (value > 0) {
            const total = ctx.chart.getDatasetMeta(0).total;
            let percentage = (value * 100 / total).toFixed(2) + "%";
            return percentage + '(' + value + ')';
          } else {
            value = "";
            return value;
          }
        }
      }
    }
  });
  }, [assets]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const removeFilter = () => {
    setFiltroPorCategoria(null);
  }

  const fetchAssetByCategory = () => {
    let labels_temp = [];
    let data_temp = [];
    if (byCategories && byCategories.length > 1) {
      byCategories.map(category => {
        labels_temp.push(capitalizeFirstLetter(category._id));
        data_temp.push(category.totales);
      })
      settingChart(labels_temp, data_temp);
    } else {
      execute("GET", `${BACKEND_URL}${GET_ASSET_BY_CAT_PATH}`).then((response) => {
        if (response) {
          setByCategories(response);
          response.map(category => {
            labels_temp.push(capitalizeFirstLetter(category._id));
            data_temp.push(category.totales);
          })
          settingChart(labels_temp, data_temp);
        }
      });
    }

  };


  const fetchAssetByArea = () => {
    let labels_temp = [];
    let data_temp = [];
    if (byAreas && byAreas.length > 1) {
      byAreas.map(category => {
        labels_temp.push(capitalizeFirstLetter(category._id));
        data_temp.push(category.totales);
      })
      settingChartArea(labels_temp, data_temp);
    } else {
      execute("GET", `${BACKEND_URL}api/asset/getByArea`).then((response) => {
        if (response) {
          setByAreas(response);
          response.map(category => {
		if (category._id){
            labels_temp.push(capitalizeFirstLetter(category._id));
            data_temp.push(category.totales);
}
          })
          settingChartArea(labels_temp, data_temp);
        }
      });
    }

  };

  // Fetching Data of All Products
  const fetchAssetsData = () => {
    if (!assets) {
      execute("GET", `${BACKEND_URL}${GET_ASSETS_PATH}`).then((response) => {
        setAssets(response);
      });
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 col-span-12 lg:col-span-10 gap-6 md:grid-cols-3 lg:grid-cols-4 mr-12">

        <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6 ">
          <div>
            <strong className="block text-sm font-medium text-gray-500">
              Asset Totales
            </strong>

            <p>
              <span className="text-2xl font-medium text-gray-900">
                {totales}
              </span>
            </p>
          </div>
        </article>

        <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6 ">
          <div>
            <strong className="block text-sm font-medium text-gray-500">
              Asignados
            </strong>

            <p>
              <span className="text-2xl font-medium text-gray-900">
                {asignados}
              </span>

              {/* <span className="text-xs text-gray-500"> from $404.32 </span> */}
            </p>
          </div>
        </article>

        <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6 ">
          <div>
            <strong className="block text-sm font-medium text-gray-500">
              En Reparacion
            </strong>

            <p>
              <span className="text-2xl font-medium text-gray-900">
                {enReparacion}
              </span>
            </p>
          </div>
        </article>

        <article className="flex flex-col gap-4 rounded-lg border border-gray-100 bg-white p-6 ">
          <div>
            <strong className="block text-sm font-medium text-gray-500">
              Obsoletos
            </strong>

            <p>
              <span className="text-2xl font-medium text-gray-900">
                {obsoletos}
              </span>
            </p>
          </div>
        </article>

        <div className="flex justify-evenly bg-white rounded-lg py-8 col-span-full">
          <div className="w-full m-auto max-h-96">
            {printChart && <Bar data={chart.data} options={chart.config} />}
          </div>

          {filtroPorCategoria &&
            <div className='flex-wrap flex-row'>
              <Button as="input" type="Input" value="Quitar Filtros [X]" className='mr-4 mt-2' variant="link" onClick={removeFilter} />
            </div>
          }
          <div className="w-full m-auto max-h-96">
            {data && <Pie data={data} plugins={[ChartDataLabels]} options={pieOptions} />}
          </div>
        </div>


        <div className="bg-white rounded-lg p-8 col-span-full">
          <div className="w-full m-auto max-h-196">
            {chartAreas && <Bar data={chartAreas.data} options={chartAreas.config} />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
