import { useMsal } from '@azure/msal-react';
import { protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';

export const Proyectos = (props) => {

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const { instance } = useMsal();

  var request = {
    scopes: ["User.Read.All"],
  };


  return (
    <>
        <div className="data-area-div">
          <img
            className="block w-2/5 mx-auto"
            src={require("../assets/workinprogress.jpg")} alt="work in progress" />
        </div>
    </>
  );
};
