import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import SideMenu from "./SideMenu";
import { RouteGuard } from './RouteGuard';

function Layout() {
  return (
    <>
      <div className="lg:h-16">
      <RouteGuard>
        <Header />
        </RouteGuard>
      </div>
      <div className="grid grid-cols-12 bg-gray-100 items-baseline">
        <div className="col-span-2 h-screen sticky top-0 hidden lg:flex">
          <SideMenu />
        </div>
        <RouteGuard>
        <Outlet />
        </RouteGuard>
      </div>
    </>
  );
}

export default Layout;
