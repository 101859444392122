import React, { useState } from "react";
import { Tab } from '@headlessui/react';
import { UserList } from "../components/Usuarios";
import { Proyectos } from "../components/Proyectos";

//TODO ver de donde sacar las settings, los valores seguro van a ser de la base de datos
// no se que settings agregar, podria dejarlo para el final de todo, porque no es algo requerido al principio
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Settings() {
  const [settings, setAllSettings] = useState([]);

  let [categories] = useState({
    Usuarios: [],
    Proyectos: [],
  })

  return (
    <div className="col-span-12 lg:col-span-10 flex justify-center ">
      <div className=" flex flex-col gap-5 w-11/12 border-2">
        <div className="flex justify-between">
          <span className="pt-4 pl-1 font-bold ring-blue-400">Configuraciones</span>
         
        </div>
        <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-white text-blue-700 shadow'
                    : 'text-blue-500 hover:bg-white/[0.12] hover:text-white'
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="py-2">
          {Object.keys(categories).map((category, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                'rounded-xl bg-white-500 p-3',
                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
              )}
            >
              {category == "Usuarios" && <UserList />}
              {category == "Proyectos" && <Proyectos />}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>

        {settings.map((element, index) => {
          return (
            <div
              className="bg-white w-50 h-fit flex flex-col gap-4 p-4 "
              key={element._id}
            >
              <div>
                <img
                  alt="store"
                  className="h-60 w-full object-cover"
                  src={element.image}
                />
              </div>
              <div className="flex flex-col gap-3 justify-between items-start">
                <span className="font-bold">{element.name}</span>
                <div className="flex">
                  <img
                    alt="location-icon"
                    className="h-6 w-6"
                    src={require("../assets/location-icon.png")}
                  />
                  <span>{element.address + ", " + element.city}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Settings;
