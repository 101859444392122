import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { protectedResources } from "../authConfig";
import HistoryTable from "../components/HistoryTable";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { useMsal } from '@azure/msal-react';

import { BACKEND_URL, ESTADOS_ASSETS, TIPOS_ASSETS } from '../constants';

const estados = [ESTADOS_ASSETS.asignado, ESTADOS_ASSETS.sinAsignar, ESTADOS_ASSETS.enReparacion, ESTADOS_ASSETS.obsoleto, ESTADOS_ASSETS.extraviado, ESTADOS_ASSETS.robado]

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// este es el modal de Asset
export default function AddAsset({
  addAssetModalSetting,
  handlePageUpdate,
  updateAssetData = null
}) {
  
  const { instance } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiTodoList.scopes,
  });

  const [asset, setAsset] = useState(updateAssetData ? updateAssetData : {
    marca: "",
    modelo: "",
    numSerie: "",
    asignadoA: "",
    factura: "",
    remito: "",
    tipo: TIPOS_ASSETS.fisico,
    estado: ESTADOS_ASSETS.sinAsignar,
    aclaraciones: ""
  });

  //console.log("----",asset)
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [categorias, setCategorias] = useState([]);
  const [campos, setCampos] = useState([]);
  const [sectores, setSectores] = useState([]);
  const [viewHistory, setViewHistory] = useState(false);
  const [assetHistory, setAssetHistory] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [selected, setSelected] = useState(updateAssetData ? updateAssetData.estado : ESTADOS_ASSETS.sinAsignar)

  useEffect(() => {
    fetchAllCategories();
    fetchAllCampos();
    fetchAllSectores();
    fetchAllUsuarios();
    fetchAssetHistory();
  }, [execute]);


  const fetchAllUsuarios = () => {
    execute("GET", `${BACKEND_URL}api/users/getAllUsers`).then((response) => {
      if (!(response && response.length < 10)) {
        setUsuarios(response);
      }
    })
  }

  const fetchAssetHistory = () => {
    if (updateAssetData){
      execute("GET", `${BACKEND_URL}api/asset/getAllHistory/${updateAssetData._id}`).then((response) => {
        if (response) {
          setAssetHistory(response);}
      });
    }
  };

  const fetchAllSectores = () => {
    execute("GET", `${BACKEND_URL}api/asset/getAllSectores`).then((response) => {
      if (response) setSectores(response);
    });
  };

  const fetchAllCategories = () => {
    execute("GET", `${BACKEND_URL}api/asset/getAllCategories`).then((response) => {
      if (response) setCategorias(response);
    });
  };

  const handleViewHistory = () => {
    setViewHistory(!viewHistory);
  }

  const fetchAllCampos = () => {
    execute("GET", `${BACKEND_URL}api/campos/getAllCampos`).then((response) => {
      if (response) setCampos(response);
    });
  };

  const handleInputChange = (key, value) => {
    setAsset({ ...asset, [key]: value });
  };

  const handleAsignadoAChange = (nombre, id) => {
    let copy_asset = asset;
    copy_asset['asignadoA'] = nombre;
    copy_asset['idAsignadoA'] = id;
    setAsset(copy_asset);
    setAsset({ ...asset, ['asignadoA']: nombre });
    //setAsset({ ...asset, ['idAsignadoA']: id });

    if(nombre!="") setSelected("Asignado")
    else setSelected("Sin Asignar")
  };

  const addAsset = () => {
    let tempAsset = asset;
    if (updateAssetData) {
      tempAsset['_id'] = updateAssetData._id;
    }
    tempAsset['estado'] = selected;
    if (selected == "Sin Asignar") {
      tempAsset['asignadoA']='';
      tempAsset['idAsignadoA']='';
    }

    if(selected==ESTADOS_ASSETS.obsoleto || selected==ESTADOS_ASSETS.extraviado || selected==ESTADOS_ASSETS.robado) tempAsset['aclaraciones']='';
    
    // agrego info del usuario para el historico    
    tempAsset['usuario'] = activeAccount["name"];
    tempAsset['usuario_email'] = activeAccount["username"];
    
    setAsset(tempAsset);
      execute("POST", `${BACKEND_URL}api/asset/add`, tempAsset).then((response) => {
      if (response) {
        handlePageUpdate();
        addAssetModalSetting();
        console.log("Asset creado correctament");
      }
    })
  };

  return (
    // Modal
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl max-h-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="flex flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>

                    <div className="mt-1 text-left sm:mt-0 sm:ml-4 sm:text-left ">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold leading-6 text-gray-900 "
                      >
                        {updateAssetData ? "Editar Asset" : "Agregar Asset"}
                      </Dialog.Title>
                      <form className="group" noValidate>
                        <div className="grid gap-4 mb-4 mt-6 sm:grid-cols-4">
                          <span className="sm:col-span-4 mt-2 text-sm peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                            Completa todos los campos genericos de Assets
                          </span>

                          <div className="sm:col-span-full">

                            {/* ------------------------------------------------------------------------ */}
                            {/* ------------------------------------------------------------------------ */}
                            <label
                              htmlFor="estado"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Estado
                            </label>
                            <div className="w-full pb-4">
                              <div className="w-full max-w-md">
                                <RadioGroup value={selected} onChange={setSelected} name="estado" className="grid grid-cols-3 gap-3" >
                                  
                                    {estados.map((estado_) => (
                                      <RadioGroup.Option
                                        key={estado_}
                                        value={estado_}
                                        className={({ active, checked }) =>
                                          `${active
                                            ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                                            : ''
                                          }
                  ${checked ? 'bg-sky-900/75 text-white' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <div className="flex w-full items-center justify-between">
                                              <div className="flex items-center">
                                                <div className="text-sm">
                                                  <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium  ${checked ? 'text-white' : 'text-gray-900'
                                                      }`}
                                                  >
                                                    {estado_}
                                                  </RadioGroup.Label>
                                                </div>
                                              </div>
                                              {checked && (
                                                <div className="shrink-0 text-white">
                                                  <CheckIcon className="h-6 w-6" />
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    ))}
                                </RadioGroup>
                              </div>
                            </div>
                            {/* ------------------------------------------------------------------------ */}
                            {/* ------------------------------------------------------------------------ */}

                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="tipo"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Tipo
                            </label>
                            <select
                              name="tipo"
                              id="tipo"
                              value={asset.tipo}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            >
                              <option selected="" value="fisico">
                                Fisico
                              </option>
                              <option value="digital">Digital</option>
                            </select>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="categoria"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              Categoria
                            </label>
                            <select
                              name="categoria"
                              id="categoria"
                              value={asset.categoria}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
			      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                            >
                              <option disabled selected value> -- Elije una opcion -- </option>
                              {categorias.map((element, index) => {
                                if (element.tipo.toLowerCase() == asset.tipo.toLowerCase())
                                  return (
                                    <option key={element.nombre} value={element.nombre}>{element.nombre_display}</option>)
                              })}
                            </select>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="marca"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Marca </span>
                              <input
                                type="text"
                                id="marca"
                                name="marca"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                value={asset.marca}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                required
                                pattern="[a-z0-9._%+-]+"
                              />
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="modelo"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Modelo</span>
                              <input
                                type="text"
                                id="modelo"
                                name="modelo"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                value={asset.modelo}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                required
                                pattern="[a-z0-9._%+-]+"
                              />
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="numeroSerie "
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Numero de Serie</span>
                              <input
                                type="text"
                                id="numSerie"
                                name="numSerie"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                value={asset.numSerie}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                              />
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="remito"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Remito</span>
                              <input
                                type="text"
                                id="remito"
                                name="remito"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                value={asset.remito}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                              />
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="factura"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Factura</span>
                              <input
                                type="text"
                                id="factura"
                                name="factura"
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                value={asset.factura}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                              />
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="asignado"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Asignado a</span>
                              <select
                                name="asignadoA"
                                id="asignadoA"
                                value={asset['idAsignadoA']}
                                onChange={(e) =>
                                  handleAsignadoAChange(e.target.options[e.target.selectedIndex].text, e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                              >
                                <option disabled selected value> -- Elije una opcion -- </option>
                                {usuarios && usuarios.map((element, index) => {
                                  return (
                                    <option key={element.id} value={element.id}>{element.displayName}</option>
                                  )
                                })}
                              </select>

                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="sector"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Sector</span>
                              <select
                                name="sector"
                                id="sector"
                                value={asset.sector}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                              >
                                <option disabled selected value> -- Elije una opcion -- </option>
                                {sectores.map((element, index) => {
                                  return (
                                    <option key={element.nombre} value={element.nombre}>{element.nombre}</option>
                                  )
                                })}
                              </select>
                            </label>
                          </div>

                          <div className="sm:col-span-2">
                            <label
                              htmlFor="ubicacion"
                              className="block mb-2 text-sm font-medium text-gray-900"
                            >
                              <span>Ubicacion</span>

                              <select
                                name="ubicacion"
                                id="ubicacion"
                                value={asset.ubicacion}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                              >
                                <option disabled selected value> -- Elije una opcion -- </option>
                                <option key="bsas" value="bsas">Buenos Aires</option>
                                <option key="allen" value="allen">Allen</option>
                                <option key="añelo" value="añelo">Añelo</option>

                              </select>
                            </label>
                          </div>

                          {(selected=='Obsoleto' || selected=='Extraviado' || selected=='Robado') &&
                            <div className="sm:col-span-4">
                              <label
                                htmlFor="aclaraciones"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                <span>Aclaraciones</span>

                                <textarea
                                  id="aclaraciones"
                                  rows="8"
                                  name="aclaraciones"
                                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                  value={asset.aclaraciones}
                                  onChange={(e) =>
                                    handleInputChange(e.target.name, e.target.value)
                                  }
                                />

                              </label>
                            </div>
                          
                          }

                          {!updateAssetData && asset.categoria && (asset.categoria !== "mouse" && asset.categoria !== "kit" && asset.categoria !== "monitor" && asset.categoria !== "teclado") && (<>
                            <hr
                              className="sm:col-span-4 my-2 h-1 border-t-0 bg-neutral-100 opacity-100"
                            />
                            <span className="sm:col-span-4 mt-2 text-sm peer-[&:not(:placeholder-shown):not(:focus):invalid]:block">
                              Completa todos los campos correspondientes a <b>{asset.categoria}</b>
                            </span>
                          </>)
                          }

                          {!updateAssetData && asset.categoria &&
                            campos.filter(e => e.categoria == asset.categoria).map((element, index) => {
                              let fieldCaption = element.nombre;//IMEI
                              let fieldName = element.field;
                              let fieldFormato = element.formato;
                              return (
                                <>
                                  {fieldFormato != 'selection' && (<div className="sm:col-span-2">
                                    <label
                                      htmlFor={fieldCaption}
                                      id={fieldCaption}
                                      className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                      <span>{fieldCaption}</span>
                                      <input
                                        type="text"
                                        id={fieldCaption}
                                        name={fieldCaption}
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                                        value={asset[`asset.${fieldName}`]}
                                        onChange={(e) =>
                                          handleInputChange(fieldName, e.target.value)
                                        }
                                        required
                                        pattern="[a-z0-9._%+-]+"
                                      />
                                    </label>
                                  </div>
                                  )}{fieldFormato == 'selection' && (<div className="sm:col-span-2">
                                    <label
                                      htmlFor={fieldCaption}
                                      className="block mb-2 text-sm font-medium text-gray-900"
                                    >
                                      <span>{fieldCaption}</span>

                                      <select
                                        name={fieldCaption}
                                        id={fieldCaption}
                                        value={asset[`asset.${fieldName}`]}
                                        onChange={(e) =>
                                          handleInputChange(e.target.name, e.target.value)
                                        }
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"

                                      >
                                        {element.opciones.map((element, index) => {
                                          return (
                                            <option key={element} value={element}>{element}</option>)
                                        })}
                                      </select>
                                    </label>
                                  </div>)}
                                </>
                              )
                            })
                          }
                        </div>
                        <div className="flex flex-column items-center space-x-4">
                        {updateAssetData && (
                          <>
                            <button
                              type="button"
                              className="mt-3 w-full rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={() => handleViewHistory()}
                            >
                              Ver historial
                            </button>
                            <br />
                          </>
                        )}
                        </div>

                        {viewHistory && (
                              <div className="wrap w-full" > 
                                <HistoryTable data={assetHistory} />
                              </div>
                            )}
                        <div className="sm:flex sm:flex-row-reverse sm:px-6">
                          <button
                            type="submit"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto group-invalid:pointer-events-none group-invalid:opacity-30"
                            onClick={addAsset}
                          >
                            {updateAssetData ? "Editar Asset" : "Agregar Asset"}
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => addAssetModalSetting()}
                            ref={cancelButtonRef}
                          >
                            Cancelar
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
