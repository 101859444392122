import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { ComputerDesktopIcon } from "@heroicons/react/24/outline";
import { HomeIcon } from "@heroicons/react/24/outline";
import { groups } from "../authConfig";
import { useMsal } from '@azure/msal-react';

function SideMenu() {
  const { instance } = useMsal();
  const [enableActions, setEnableActions] = useState(false);
  const activeAccount = instance.getActiveAccount() || instance.getAllAccounts()[0];

  const linkStyle = { textDecoration: "none" };

  useEffect(() => {
    checkPermissions();
  }, [activeAccount]);

  
  const checkPermissions = () => {
    //TODO si tiene los permisos necesarios entonces puede borrar el asset enableActions
    const hasRequiredGroup = activeAccount?.idTokenClaims?.groups?.includes(groups.groupAdmin)
    if (hasRequiredGroup) setEnableActions(true);
  }

  return (
    <div className="h-full flex-col justify-between  bg-white hidden lg:flex ">
      <div className="px-1 py-2">
        <nav aria-label="Main Nav" className="mt-6 flex flex-col space-y-1">
          <Link
            style={linkStyle}
            to="/"
            className="flex items-center gap-2 rounded-lg hover:bg-gray-100 px-4 py-2 text-gray-700"
          >
            <HomeIcon className="h-6 w-6 text-grey-500 inline hover:text-blue-800" />

            <span className="text-md font-medium"> Dashboard </span>
          </Link>

          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
              <Link to="/inventory"
                style={linkStyle}>
                <div className="flex items-center gap-2 text-slate-600">
                  <ComputerDesktopIcon className="h-6 w-6 text-grey-500 inline hover:text-blue-800" />
                  <span className="text-md font-medium"> StockIT</span>
                </div>
              </Link>
            </summary>
          </details>

          <Link
            style={linkStyle}
            to="/tickets"
            className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700"
          >
            <BriefcaseIcon className="h-6 w-6 text-grey-500 inline hover:text-blue-800" />
            <span className="text-md font-medium"> Tickets</span>
          </Link>

        </nav>
      </div>

      {enableActions && (
        <div className="sticky inset-x-0 bottom-0">
          <hr className="my-2 h-2 border-t-0 bg-neutral-300 opacity-100" />
          <details className="group [&_summary::-webkit-details-marker]:hidden">
            <summary className="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
              <Link to="/settings">
                <div className="flex items-center gap-2">
                  <img
                    alt="store-icon"
                    src={require("../assets/config_icon.png")}
                    width={"20rem"}
                    height={"auto"}
                  />
                  <span className="text-sm font-medium"> Configuraciones </span>
                </div>
              </Link>
            </summary>
          </details>
        </div>
      )}
    </div>
  );
}

export default SideMenu;
