import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function CompletarCampoModal({ showModal, razonAPendiente, setShowComentarioPendiente}) {
  const [show, setShow] = useState(showModal);
  const [razon, setRazon] = useState('');

  const handleClose = () => {
    setShow(false);
    setShowComentarioPendiente(false);
  }

  const handleRazon = () => {
    if (razon && razon.length > 5){
      setShow(false);
      razonAPendiente(razon);
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Pasar a Pendiente de Informacion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Acciones a realizar por quien levanto el requerimiento: </Form.Label>
            <Form.Control as="textarea" rows={4} value={razon} 
              onChange={(e) => setRazon(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleRazon}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CompletarCampoModal
